/* eslint-disable no-console */
import * as ReactDOMClient from 'react-dom/client';
import 'assets/styles/index.scss';
import App from 'views/App';
import reportWebVitals from './reportWebVitals';

const container: any = document.getElementById('root');
const root = ReactDOMClient.createRoot(container);

root.render(<App />);
reportWebVitals();
