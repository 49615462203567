import { Suspense, lazy } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

declare global {
  interface Window {
    Android: any;
    webkit: any;
  }
}

function App() {
  const PaywallApple = lazy(() => import('./paywall/Apple'));
  const PaywallGoogle = lazy(() => import('./paywall/Google'));

  const GiftApple = lazy(() => import('./gift/Apple'));
  const GiftGoogle = lazy(() => import('./gift/Google'));

  const Privacy = lazy(() => import('./terms/Privacy'));
  const Marketing = lazy(() => import('./terms/Marketing'));

  const OnboardingIntro = lazy(() => import('./onboarding/OnboardingIntro'));
  const OnboardingPush = lazy(() => import('./onboarding/OnboardingPush'));
  const OnboardingAlarm = lazy(() => import('./onboarding/OnboardingAlarm'));

  const ClassList = lazy(() => import('./class/ClassList'));
  const ClassDetail = lazy(() => import('./class/ClassDetail'));

  const Profile = lazy(() => import('./user/Profile'));
  const GradeRoadmap = lazy(() => import('./user/GradeRoadmap'));

  return (
    <BrowserRouter>
      <Suspense fallback={<div />}>
        <Routes>
          <Route path="/paywall/apple/*" element={<PaywallApple />} />
          <Route path="/paywall-2023-apple-7days-free" element={<PaywallApple />} />
          <Route path="/paywall/google/*" element={<PaywallGoogle />} />
          <Route path="/paywall-2023-google-7days-free" element={<PaywallGoogle />} />

          <Route path="/gift/apple/*" element={<GiftApple />} />
          <Route path="/gift/google/*" element={<GiftGoogle />} />

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/marketing" element={<Marketing />} />

          <Route path="/onboarding/intro" element={<OnboardingIntro />} />
          <Route path="/onboarding/alarm" element={<OnboardingAlarm />} />
          <Route path="/onboarding/push" element={<OnboardingPush />} />

          <Route path="/class" element={<ClassList />} />
          <Route path="/class/:classId" element={<ClassDetail />} />

          <Route path="/users/:userId" element={<Profile />} />
          <Route path="/users/roadmap/:grade" element={<GradeRoadmap />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}

export default App;
